<template>
  <modal-card :title="`Invoice #${invoice.invoiceNum}`" :cancel-text="'Close'">
    <invoice :invoice="invoice" @print="$emit('close')" />
  </modal-card>
</template>

<script>
export default {
  name: "InvoiceModal",
  components: {
    invoice: () => import("@shared/invoices/_invoice")
  },
  props: {
    invoice: {
      required: true,
      type: Object
    }
  }
};
</script>
